<template>
  <div class="checkinMainDiv">
    <section class="mainFourSquare">
      <div class="swarmLogo"></div>
      <div class="topDiv"></div>
      <div class="checkinUser wow fadeInRight">
        <div v-bind:style="styleObject" class="mypoligon"></div>
      </div>
      <div class="textDiv wow fadeInLeft">
        <h1>
          {{model.title}}
          <br />HOŞGELDİNİZ
        </h1>
      </div>
      <div class="bottomDiv"></div>
    </section>
  </div>
</template>
<script>
import store from "../store/index";
import storage from "../storage/modules/checkin";
import WOW from "wow.js";
new WOW().init();
export default {
  name: "foursquare-checkin",
  props: ["data"],
  created() {
    this.startTimer();
  },
  data() {
    return {
      styleObject: {}
    };
  },
  methods: {
    async startTimer() {
      this.styleObject = {
        "--background": "url('" + this.model.image + "')"
      };
      var checkin = await storage.getCheckinByCheckinId(this.model);
      if (checkin) {
        store.dispatch("foursquareCheckin/showNextCheckin");
        return;
      }

      await storage.addCheckin(this.model);
      setTimeout(() => {
        store.dispatch("foursquareCheckin/showNextCheckin");
      }, 20000);
    }
  },
  computed: {
    model() {
      return this.$store.state.foursquareCheckin;
    }
  }
};
</script>

<style lang="scss">
@import "../assets/animate/animate.css";
.checkinMainDiv {
  width: 100vw;
  height: 100vh;
  background: white;
}
.topDiv {
  width: 100%;
  height: 50%;
  background: transparent;
  background-image: url(/images/foursquare/swarmbg.png);
  background-size: 100% 103%;
  background-position: center center;
}
.checkinUser {
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  margin: auto;
}

.bottomDiv {
  width: 100%;
  height: 50%;
  background: transparent;
}

.swarmLogo {
  background-image: url(/images/foursquare/swarmlogo.png);
  float: right;
  position: absolute;
  right: 40px;
  top: 25px;
  z-index: 99;
  width: 200px;
  height: 50px;
  background-size: cover;
}

.mainFourSquare {
  background-image: url(/images/foursquare/bg.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
}

.textDiv {
  position: fixed;
  margin-top: 120px;
  text-align: center;
  width: 100%;
  font-family: sans-serif;
  text-align: center;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 120px;
}

.textDiv h1 {
  font-size: 90px;
  color: #ffaa00;
  font-weight: bold;
  margin: auto;
}

.mypoligon {
  margin: auto;
  height: 590px;
  width: 590px;
  background-color: white;
  -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  border-bottom: 5px solid #ffaa00;
}

.mypoligon::before {
  content: "";
  display: block;
  position: relative;
  top: 5px;
  left: 5px;
  height: 580px;
  width: 580px;
  background-image: var(--background);
  -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  background-size: cover;
  background-position: center;
}

.mypoligon::after {
  content: "";
  display: block;
  position: relative;
  bottom: 617px;
  left: 5px;
  height: 2225px;
  width: 580px;
  -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  background: #ffaa00;
  z-index: -1;
}
</style>
